@import "../theme";

$cardWidth: 375px;

.article {
  height: 472px;
}

.repo {
  height: 248px;
}

.card {
  @media screen and (min-width: 600px) {
    margin: $spaceLG;
  }
  @media screen and (max-width: 600px) {
    margin: $spaceLG $spaceSM;
  }
  position: relative;
  width: $cardWidth;
  border-radius: $borderRadiusFrames;
  padding: $spaceLG;
  border: 1px solid #eeeeee;
  -webkit-box-shadow: 8px 8px 8px 0px rgba(170, 170, 170, 1);
  -moz-box-shadow: 8px 8px 8px 0px rgba(170, 170, 170, 1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

  //-webkit-box-shadow: 10px 10px 0px 0px rgba(99,179,237,0.75);
  //-moz-box-shadow: 10px 10px 0px 0px rgba(99,179,237,0.75);
  //box-shadow: 10px 10px 0px 0px rgba(99,179,237,0.75);

  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
  //&::before {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //  content: " ";
  //  opacity: 1;
  //  border-radius: $borderRadiusFrames;
  //  @media screen and (max-width: 600px) {
  //    background: #fff;
  //    transform: scale(1.1);
  //    box-shadow: 3px 3px 4px 3px rgba(204, 204, 204, 0.6);
  //  }
  //  @media screen and (min-width: 600px) {
  //    transition: all 0.3s ease-in-out;
  //  }
  //}
  //
  //&:hover::before {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 100%;
  //  opacity: 1;
  //  padding: $spaceLG;
  //
  //  @media screen and (min-width: 600px) {
  //    background-color: $colorLightBackground;
  //    transform: scale(1.1);
  //  }
  //}
}

.contentBackground {
  position: absolute;
  width: calc(#{$cardWidth} - 2 * #{$spaceLG});
  z-index: 1;
}

.repoGrid {
  display: grid;
  grid-gap: $spaceXS;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 48px 72px 20px 20px 30px;
}

.articleGrid {
  display: grid;
  grid-gap: $spaceXS;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 220px 48px 72px 20px 20px 30px;
}

.image {
  border-radius: $borderRadiusFrames;
  margin: 0;
  background-image: url("https://cdn-images-1.medium.com/max/250/1*u3m_MS6jKpFb-9j8a2IZmw.jpeg");
  background-size: cover;
  width: calc(#{$cardWidth} - 2 * #{$spaceLG});
  height: 220px;
}

.link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  grid-column: 1 / -1;
}

.title {
  height: 48px;
  color: $colorText;
  padding: 0;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.5em;
  max-height: 3.8em;
}

.snippet {
  height: 72px;
  color: $colorText;
  padding: 0;
  font-size: 1em;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 1.5em;
  max-height: 4.5em;
  grid-column: 1 / -1;
}

.publication {
  padding: 0;
  font-size: 0.9rem;
  color: $colorText;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-column: 1 / -1;
}
.footer {
  padding: $spaceMD $spaceXS $spaceXS 0;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 30% 40% 30%;
}

.sourceIcon {
  margin-right: $spaceXXS;
}

.icon {
  height: 16px;
  width: 16px;
  margin-right: $spaceXS;
  color: $colorIcon;
}
.metadata {
  color: $colorLightText;
  font-size: $textXS;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.metaStart {
  justify-content: flex-start;
}
